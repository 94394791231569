<template>
  <div class="c-container ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="c-col-full sm:c-col-4 md:c-col-8 lg:c-col-6">
        <div class="ui-relative c-bg-white ui-rounded-md">
          <!-- Content -->
          <div class="c-row ui-h-full ui-p-8 ui-pb-0">
            <!-- Coluna esquerda -->
            <div class="c-col-full sm:c-col-1/2 ui-pb-8 sm:ui-mt-0">
              <div class="ui-flex ui-flex-col ui-h-full">
                <!-- Título e subtitulo -->
                <div class="ui-flex-1">
                  <!-- Titulo -->
                  <div class="c-text-subtitle">
                    <span>Tem certeza que quer excluir este cartão?</span>
                  </div>

                  <!-- Subtitulo -->
                  <div class="c-text-b2 ui-mt-3">
                    <span>Você poderá adicioná-lo novamente a qualquer momento.</span>
                  </div>
                </div>

                <!-- Botão -->
                <div class="ui-pt-4 md:ui-inline-flex">
                  <c-button
                    full
                    size="lg"
                    :loading="loading"
                    @click="onConfirm"
                  >
                    Confirmar
                  </c-button>

                  <c-button
                    outline
                    full
                    size="lg"
                    class="ui-mt-4 md:ui-mt-0 md:ui-ml-4"
                    @click="$modal.hide()"
                  >
                    Não
                  </c-button>
                </div>
              </div>
            </div>

            <!-- Coluna direita -->
            <div class="c-col-full sm:c-col-1/2">
              <!-- Ilustração -->
              <div class="c-d-flex ui-h-full ui-justify-center">
                <img
                  src="@/assets/images/p-modal/warning.svg"
                  class="ui-mt-auto"
                  alt="Ilustração"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CButton } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'GenericModal',
  components: {
    CButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    onDeleteButtonClick: {
      type: Function,
      required: true,
    },
  },
  methods: {
    async onConfirm() {
      this.loading = true;
      await this.onDeleteButtonClick();
      this.loading = false;
      this.$modal.hide();
    },
  },
};
</script>
