<template>
  <div>
    <div class="c-row c-horizontal-center h-full">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <div class="c-d-flex ui-w-full ui-mb-4">
          <div class="c-d-flex ui-flex-1 c-text-h3">
            <c-skeleton-block
              v-if="loading"
              width="8rem"
            />
            <span v-else>
              Cartões
            </span>
          </div>
          <!-- Botão Cadastrar novo cartão -->
          <div class="c-d-flex c-vertical-end">
            <div
              v-if="loading"
              class="c-d-flex ui-h-4 ui-space-x-2"
            >
              <c-skeleton-circle size="1rem" />
              <c-skeleton-block width="10rem" />
            </div>
            <router-link
              v-else
              :to="{ name: 'cadastrar-cartao' }"
              data-cy="doAddNewCard"
            >
              <c-link
                class="c-text-gray-300 c-d-flex ui-whitespace-no-wrap"
                component="button"
              >
                <icon
                  name="Edit"
                  color="var(--c-color-gray-300)"
                />
                Cadastrar novo cartão
              </c-link>
            </router-link>
          </div>
        </div>

        <div class="ui-rounded-lg ui-overflow-hidden ui-space-y-2">
          <template v-if="loading">
            <!-- Skeleton dos cards de cartão -->
            <div
              class="ui-p-6 c-bg-white c-d-flex"
              v-for="i in 3"
              :key="i"
            >
              <div class="ui-mr-8">
                <c-skeleton-block
                  radius="0.5rem"
                  width="50px"
                  height="50px"
                />
              </div>
              <div class="c-text-b2">
                <c-skeleton-block
                  height="1rem"
                  width="12rem"
                />
                <c-skeleton-block
                  class="ui-mt-1"
                  height="1rem"
                  width="10rem"
                />
                <c-skeleton-block
                  class="ui-mt-1"
                  height="1rem"
                  width="11rem"
                />
              </div>
            </div>
          </template>
          <template v-else-if="creditCards.length">
            <!-- Cards dos cartões -->
            <credit-card
              v-for="(card, index) in creditCards"
              :key="index"
              :card="card"
              @remove="onRemoveCreditCard"
            />
          </template>
          <div
            v-else
            class="c-bg-white ui-p-6 c-text-subtitle"
          >
            Você ainda não tem cartões de crédito cadastrado nesta plataforma.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { OrderStoreKeys } from '@/store/modules/order';
import { CSkeletonBlock, CSkeletonCircle, Icon, CLink } from '@estrategiahq/coruja-web-ui';
import CreditCard from './components/credit-card.vue';
import ModalExcluir from './components/modal-excluir.vue';

export default {
  name: 'MinhasComprasPage',
  components: {
    CSkeletonBlock,
    CSkeletonCircle,
    CreditCard,
    Icon,
    CLink,
  },
  async created() {
    await this.$store.dispatch(OrderStoreKeys.ACTION_FETCH_CREDIT_CARDS);
  },
  computed: {
    ...mapGetters({
      creditCards: OrderStoreKeys.GET_CREDIT_CARDS,
      loading: OrderStoreKeys.GET_REQUEST_LOADING,
    }),
  },
  methods: {
    onRemoveCreditCard (cardId) {
      this.$modal.show({
        component: ModalExcluir,
        props: {
          onDeleteButtonClick: async () => {
            try {
              await this.$store.dispatch(OrderStoreKeys.ACTION_DELETE_CREDIT_CARD, cardId);
              this.$toast.show({ type: 'success', text: 'Cartão excluído com sucesso.' });
            } catch {
              this.$toast.show({ type: 'error', text: 'Houve um erro ao excluir o cartão.' });
            }
          },
        },
      });
    },
  },
};
</script>
