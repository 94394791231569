<template>
  <div class="ui-p-6 c-bg-white">
    <div class="c-d-flex ui-flex-wrap">
      <div class="ui-mr-8">
        <div class="c-d-flex c-vertical-center ui-h-full">
          <img
            :src="card.brandIcon"
            :alt="`Bandeira ${card.brand}`"
            width="50"
          >
        </div>
      </div>
      <div class="ui-flex-1">
        <div class="c-text-b2 c-text-gray-400">
          Cartão {{ card.brand }}
        </div>
        <div class="c-text-b3 c-text-gray-300">
          •••• •••• •••• {{ card.lastFourDigits }}
        </div>
        <div class="c-text-b3 c-text-gray-300">
          Válido até {{ formattedDate }}
        </div>
      </div>
      <div class="">
        <button @click="deleteCard">
          <icon
            name="trash"
            color="var(--c-color-gray-300)"
            class="ui-w-4"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { CreditCard } from '@/models/creditCard';
import { Icon } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'CreditCard',
  components: { Icon },
  props: {
    card: {
      type: CreditCard,
      required: true,
    },
  },
  computed: {
    formattedDate () {
      const month = String(this.card.expirationMonth).padStart(2, '0');
      const year = String(this.card.expirationYear).slice(-2);
      return `${month}/${year}`;
    },
  },
  methods: {
    deleteCard () {
      this.$emit('remove', this.card.id);
    },
  },
};
</script>
